//=include plugins/polybg.js

$( document ).ready(function() {

    //=include plugins/navigation.js
    //=include plugins/smooth.js
    //=include plugins/scrollreveal.js
    //=include plugins/fontfaceobserver.standalone.js
    //=include ../../node_modules/jquery-match-height/dist/jquery.matchHeight.js

});